import React from 'react'
import Layout from "../../components/Layout";
import './index.scss';

const MapPage = ({location}) => (
    <Layout location={location}>
        <section className="section map-section">
            <div className="container has-text-centered map-wrapper">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2551.7785774989698!2d20.078545415960207!3d50.240041379446794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471652eb8dc089e9%3A0x57885bf1b112d6b5!2sKancelaria%20Notarialna%20Zofia%20Bzdyl%20i%20Ma%C5%82gorzata%20Bere%C5%9B-Bzdyl%20Notariusze%20s.c.!5e0!3m2!1spl!2spl!4v1595967225502!5m2!1spl!2spl"
                        width="100%" height="100%" frameBorder="0" style={{border: '0'}} allowFullScreen=""
                        aria-hidden="false" tabIndex="0"/>
                </div>
        </section>
    </Layout>
)

export default MapPage
